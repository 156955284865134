app.config(["$httpProvider", function($httpProvider) {
  return $httpProvider.interceptors.push(["$q", "$location", "$window", '$injector', "appVersion", "APP_KEY_NAME", "ErrFactory",
    function(                              $q,   $location,   $window,   $injector,   appVersion,   APP_KEY_NAME,   ErrFactory) {
    function logApiCall(response) {
      if (response && response.config && response.config.url &&
          response.config.url.indexOf('http') === 0 ) {
        Promise.resolve()
          .then(() => {
            var apiCalls = window.localStorage.getItem('apiCalls');
            var data = {
              path: response.config.url,
              response_code: response.status,
              method: response.config.method,
              timeStamp: Math.floor((new Date).valueOf() / 1000),
              severity: 'INFO',
              additional_data: [{
                response: JSON.stringify(response.data),
                body: response.config.data,
                requestHeaders: response.config.headers,
                userAgent: $window.navigator.userAgent,
              }]
            };

            apiCalls = apiCalls ? JSON.parse(apiCalls) : [];
            apiCalls.push(data);
            if (apiCalls.length > 50) apiCalls.splice(0, apiCalls.length - 50);
              window.localStorage.setItem('apiCalls', JSON.stringify(apiCalls));
          });
      }
    }

    function closeConnections() {
      let AuthService = $injector.get('AuthService');
      delete $window.sessionStorage.access_token;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      AuthService._authorizedUser = null;
      $location.path("/#/sign-in");
    }

    var inFlightAuthRequest = null;

    return {
      request: function(config) {
        // don't add headers for elastic search request
        if (config.url.includes('picsum.photos')) return config;

        config.headers['X-Locale'] = localStorage.getItem('lang');
        config.headers['X-AppName'] = APP_KEY_NAME;
        config.headers['X-AppVersion'] = appVersion;
        if (config.url.includes('/v3/')) {
          config.headers['Accept'] = 'application/vnd.api+json';
          config.headers['Content-Type'] = 'application/vnd.api+json';
        }

        let accessToken = localStorage.getItem('access_token');
        if (accessToken) {
          if (accessToken.includes('Bearer ')) config.headers['Authorization'] = accessToken;
          else config.headers['Authorization'] = 'Token token=' + accessToken;
        }
        return config;
      },
      response: function (response, b) {
        if (Array.isArray(response.data[0] && response.data[0].errors)) {
          let mess = (Array.isArray(response.data[0].errors)) ? response.data[0].errors.map(e => e.title).join('\n'): response.data[0].errors;
          let err = new ErrFactory(mess);
          err.setResponse(response);
          return $q.reject(err);
        }
        let token = response.headers("authorization");
        if (token && (response.config.url.includes('/sign_in') || response.config.url.includes('/refresh'))) localStorage.setItem('access_token', token);
        return response;
      },
      responseError: function (response) {
        var AuthService = $injector.get('AuthService');
        logApiCall(response);
        if (response.status === 503) {
          let err = new ErrFactory.UnavailableServiceError();
          err.setResponse(response);
          return $q.reject(err);
        } else if (response.status === -1) {
          let err = new ErrFactory.NoInternetConnectivity();
          err.setResponse(response);
          return $q.reject(err);
        } else if (response.status === 500 ) {
          let err = new ErrFactory.UnknownServerError(response.status);
          err.setResponse(response);
          return $q.reject(err);
        } else if (response.status === 422 && response.errors && response.errors.find(e => e.code === 'invalid_zip_code')) {
          return $q.resolve(null);
        } else if (response.status === 422 && response.errors && response.errors.find(e => e.code === 'wrong_password_confirmation')) {
          let err = new ErrFactory.PasswordConfirmation();
          err.setResponse(response);
          return $q.reject(err);
        } else if (response.status === 403 && response.errors && response.errors.find(e => e.code === '2fa_unauthorized')) {
          return $q.resolve(null);
        } else if (response != null && response.status === 401 && ($window.sessionStorage.access_token || AuthService._authorizedUser)) {
            return Promise.resolve()
            .then(() => {
              if (response.data instanceof Blob) return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = () => { resolve(reader.result); };
                reader.onerror = reject;
                reader.readAsText(response.data);
              });
            })
            .then(res => {
              let blobData;
              let token = localStorage.getItem('refresh_token');
              if (res) blobData = JSON.parse(res);
              let tokenExpired = response.data.errors && response.data.errors.find(e => e.code === 'invalid_refresh_token' || e.code === 'revoked_token');
              let signatureExpired = response.data.errors && response.data.errors.find(e => e.code === 'signature_has_expired') ||
                                     response.data.message && response.data.message === 'Signature has expired' ||
                                     res && +blobData.status === 401 && blobData.message === 'Signature has expired';
              if (token && signatureExpired && !tokenExpired) {
                var deferred = $q.defer();
                if (!inFlightAuthRequest) inFlightAuthRequest = $injector.get('API').refreshAccessToken(token);
                inFlightAuthRequest.then(token => {
                  inFlightAuthRequest = null;
                  if (token) return localStorage.setItem('refresh_token', token);
                  else deferred.reject();
                })
                .then(() => $injector.get("$http")(response.config))
                .then((resp) => deferred.resolve(resp))
                .catch((err) => {
                  console.log(err);
                  inFlightAuthRequest = null;
                  closeConnections();
                  return deferred.reject();
                });
                return deferred.promise;
              }
            closeConnections();
            return $q.reject(response);
          })
          .catch(err => {
            console.log(err);
            $q.reject(err);
          })
        } else if (response.data && response.data.errors) {
          let mess;
          if (response.config.url.includes('/v3/')) mess = response.data.errors.map(e => e.title).join('\n');
          else mess = Array.isArray(response.data.errors) ? response.data.errors.map(e => e.message || e).join('\n'): response.data.errors;
          let err = new ErrFactory(mess);
          err.setResponse(response);
          return $q.reject(err);

        } else if (response.data && response.data.message) {
          let err = new ErrFactory(response.data.message);
          err.setResponse(response);
          return $q.reject(err);

        } else if (response.status) {
          let err = new ErrFactory.UnknownServerError(response.status);
          if ( response.status === -1) {
            if( navigator.onLine !== undefined ) {
              if (!navigator.onLine) err = new ErrFactory.ServerConnectionError;
            } else if (navigator.connection && navigator.connection.type == Connection.NONE) err = new ErrFactory.ServerConnectionError;
          }
          err.setResponse(response);
          return $q.reject(err);

        } else {
          return $q.reject(response);
        }
      }
    };
  }]);
}]);
